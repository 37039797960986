import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "gatsby";

const dataList = [
  {
    image: "https://picsum.photos/343/193?service-01.jpg",
    title: "Passing through",
    description:
      "HueBox LED lightboxes can be large or small and will capture the attention of the audience that is passing by. Use light to deliver dynamic and subliminal messaging that re-enforces your brand.",
  },
  {
    image: "https://picsum.photos/343/193?service-02.jpg",
    title: "Shopping",
    description:
      "Attract a captive audience as they pass through duty free with a dynamic LED HueBox delivering your brand messaging and promotional offers.",
  },
];

const dataList2 = [
  {
    image: "https://picsum.photos/343/193?service-03.jpg",
    title: "App Development",
    description:
      "There are many variations variats  of passages of Lorem Ipsum available.",
  },
  {
    image: "https://picsum.photos/343/193?service-04.jpg",
    title: "Business Design",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
];

let description =
  "Passages there are many variatin Lorem Ipsum available have suffered";
const dataList3 = [
  {
    image: "https://picsum.photos/343/193?service-03.jpg",
    title: "Stand Out",
    description:
      "Exhibitions can be busy places with a lot of competition for visitors. A HueBox can make the difference between a good show and a great one.",
  },
  {
    image: "https://picsum.photos/343/193?service-04.jpg",
    title: "Be imaginative  ",
    description:
      "Let HueBox grab the imagination and interest of passers-by, turning them into stand visitors and enquiries.",
  },
  {
    image: "https://picsum.photos/343/193?service-04.jpg",
    title: "Business Design",
    description:
      "A dynamic HueBox can differentiate your stand and will lift it above your competition. It will deliver, inform, delight and attract the target audience you need.",
  },
];

const TabThree = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Retail</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Events</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Exhibitions</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Airports</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Travel</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">How can we help you?</h4>
                                <p>
                                  You only have a few seconds to make an impact
                                  on your passing audience. Using a Huebox
                                  covering an entire wall with animation and
                                  movement will draw attention and deliver brand
                                  recognition.
                                </p>
                                <div className="read-more">
                                  <a
                                    className="btn-default btn-small"
                                    href="src/doob/elements/tab/TabThree#"
                                  >
                                    Read More
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <Link to="#service">
                                            <img
                                              src={`${val.image}`}
                                              alt="card Images"
                                            />
                                          </Link>
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                          <Link
                                            className="btn-default btn-small"
                                            to="#service"
                                          >
                                            Read More
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Whice Service Provided?
                                </h4>
                                <p>
                                  Lorem, ipsum dolor sit amet consectetur
                                  adipisicing elit. Libero ea sint nihil
                                  corrupti! Harum nemo eius odio.
                                </p>
                                <div className="read-more">
                                  <a
                                    className="btn-default btn-small"
                                    href="src/doob/elements/tab/TabThree#"
                                  >
                                    Read More
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList2.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <Link to="#service">
                                            <img
                                              src={`${val.image}`}
                                              alt="card Images"
                                            />
                                          </Link>
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                          <Link
                                            className="btn-default btn-small"
                                            to="#service"
                                          >
                                            Read More
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-12">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList3.map((val, i) => (
                                  <div
                                    className="col-lg-4 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner">
                                        <div className="image">
                                          <Link to="#service">
                                            <img
                                              src={`${val.image}`}
                                              alt="card Images"
                                            />
                                          </Link>
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          <p
                                            className="description b1 color-gray mb--0"
                                            dangerouslySetInnerHTML={{
                                              __html: val.description,
                                            }}
                                          ></p>
                                          <Link
                                            className="btn-default btn-small"
                                            to="#service"
                                          >
                                            Read More
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content"></div>
                    </TabPanel>
                    <TabPanel>
                      <div className="rn-tab-content"></div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;

import SEO from "../../common/SEO";
import Header from "../../common/header/Header";
import { graphql } from "gatsby";
import ServiceOne from "../../doob/elements/service/ServiceOne";
import Separator from "../../components/Separator";
import React from "react";
import Typed from "react-typed";
import TabThree from "../../doob/elements/tab/TabThree";
import Footer from "../../common/footer/Footer";
import * as styles from "./Sectors.module.scss";

const BannerData = [
  {
    image: "https://picsum.photos/1280/800?bg-image-6.jpg",
    title: "Airports",
    description:
      "We help our clients succeed by creating brand identities, digital experiences, and print materials.",
  },
];
const HomeDefault = ({ data }) => {
  const heroImage = data.heroImage.childImageSharp.original.src;
  console.log(data);
  return (
    <>
      <SEO title="Business Consulting" />
      <main className="page-wrapper">
        <Header transparent />

        <div
          className={`height-650 bg_image ${styles.heroBg}`}
          style={{
            backgroundImage: `url(${heroImage})`,
          }}
        >
          <div className="container" style={{ zIndex: 999 }}>
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7">
                <div className="inner text-start">
                  <h1 className="title">
                    Light up <br />
                    <Typed
                      strings={["Retail", "Airports", "Health &amp; Fitness"]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  {/*<p className="description">*/}
                  {/*  HueBox: bringing [sector] to life with dynamic animations*/}
                  {/*</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <TabThree />
          </div>
        </div>
        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container">
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Footer />
      </main>
    </>
  );
};
export default HomeDefault;
export const query = graphql`
  {
    heroImage: file(
      relativePath: {
        eq: "Hue_Box_In_Scene_Woman_Graphic_NICE_Lower_More_Reflection.jpg"
      }
    ) {
      childImageSharp {
        original {
          src
        }
        #        gatsbyImageData(
        #          width: 1920
        #          quality: 80
        #          placeholder: NONE
        #          layout: CONSTRAINED
        #        )
      }
    }
  }
`;
